import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16965a7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center d-flex align-items-center" }
const _hoisted_2 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (!_ctx.$store.state.isGoogleAuthorized)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "Per iniziare, collega il tuo account google",
            text: "Per iniziare, collega il tuo account google"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Account google collegato:",
              text: "Account google collegato:"
            }),
            _createElementVNode("b", null, " " + _toDisplayString(_ctx.$store.state.user.googleAuthData.googleEmail), 1)
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.$store.state.isGoogleAuthorized || _ctx.$store.state.user.hasRevokedAuth)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.requestAuthorize && _ctx.requestAuthorize(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Collegati con google",
              text: "Collegati con google"
            })
          ]))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-danger",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.revoke && _ctx.revoke(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Revoca autorizzazione",
              text: "Revoca autorizzazione"
            })
          ]))
    ])
  ]))
}