import * as OM from '@/model';
import store from './store';

export function JsonClone<T>(item: T){
    return <T>JSON.parse(JSON.stringify(item));
}

export function getQueryParams(url?) {
    if(!url){
        url = window.location.href;
    }
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized){}
        localized = new OM.LocalizedValue();

    if(!localized.values)
        localized.values = {};
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}
