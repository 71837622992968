import store from '@/store';
import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/menu',
            name: 'menu',
            component: () => import('./views/menu.vue'),
        },
        {
            path: '/manageDuplicates',
            name: 'manageDuplicates',
            component: () => import('./views/manageDuplicates.vue'),
        },
        {
            path: '/manageSync',
            name: 'manageSync',
            component: () => import('./views/manageSync.vue'),
        },
        {
            path: '/info',
            name: 'info',
            component: () => import('./views/info.vue'),
        },
    ];
};