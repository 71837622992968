<template>
    <div ref="rootElement">
        <div class="opPaginationContainer top" v-if="showUpperPagination">
            <div class="opSvuotaFiltri">
                <button type="button" class="btn btn-sm btn-link" @click="() => onClearFilters(null, null)">
                    <localized-text localizedKey="Svuota filtri" text="Svuota filtri"></localized-text>
                </button>
            </div>
            <div class="opPaginationNumberContainer">
                <div class="opPaginationNumber" @click="subtractPage">
                    &lt;
                </div>
                <div class="opPaginationNumber" v-for="i in pages" :key="i"
                    :class="{selected: selectedPage == i}" @click="setSelectedPage(i)">
                    {{i}}
                </div>
                <div class="opPaginationNumber" @click="addPage">
                    &gt;
                </div>
                <select class="opItemsPerPage" v-model="xItemsPerPage">
                    <option :value="10">10</option>
                    <option :value="25">25</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </div>
            <div class="opItemsTotal">Totale: <span class="opItemsTotalText">{{xData.length}}</span></div>
        </div>
        <div class="mb-4" style="width: 100%; overflow-x: auto">
            <table class="opTable" ref="tableRoot">
                <slot :items="paginated"></slot>
            </table>
        </div>
        <div class="opPaginationContainer bottom" v-if="showLowerPagination">
            <div class="opSvuotaFiltri">
                <button type="button" class="btn btn-sm btn-link" @click="() => onClearFilters(null, null)">
                    <localized-text localizedKey="Svuota filtri" text="Svuota filtri"></localized-text>
                </button>
            </div>
            <div class="opPaginationNumberContainer">
                <div class="opPaginationNumber" @click="subtractPage">
                    &lt;
                </div>
                <div class="opPaginationNumber" v-for="i in pages" :key="i"
                    :class="{selected: selectedPage == i}" @click="setSelectedPage(i)">
                    {{i}}
                </div>
                <div class="opPaginationNumber" @click="addPage">
                    &gt;
                </div>
                <select class="opItemsPerPage" v-model="xItemsPerPage">
                    <option :value="10">10</option>
                    <option :value="25">25</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </div>
            <div class="opItemsTotal">Totale: <span class="opItemsTotalText">{{xData.length}}</span></div>
        </div>
    </div>
</template>

<script lang="ts">
import { StorageServices } from '@/services/StorageServices';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class OpTable extends Vue {
    @Prop() items: any[];
    @Prop({
        default: true
    }) showUpperPagination: boolean;
    @Prop({
        default: true
    }) showLowerPagination: boolean;
    @Prop() opName: string;
    @Prop({
        type: Number,
        default: 10
    }) itemsPerPage: number;
    @Prop() clearFilters: number;
    @Prop({ default: () => { return {} } }) filtersFn: any;

    tableRoot: any;
    allItems: any[];
    xData: any[] = [];
    xItemsPerPage: number = 10;

    filters: ((item: any) => boolean)[] = [];
    sorts: SortDefinition[] = [];

    totalPages: number = 0;
    lowerPages: number = 0;
    upperPages: number = 0;
    pages: number[] = [];
    selectedPage: number = 1;


    setSelectedPage(index: number){
        this.selectedPage = index;
        this.calcPagination();
    }
    subtractPage(){
        if(this.selectedPage > 1){
            this.selectedPage--;
            this.calcPagination();
        }
    }
    addPage(){
        if(this.selectedPage < this.totalPages){
            this.selectedPage++;
            this.calcPagination();
        }
    }

    created(){
        this.allItems = this.items.slice();
        this.xData = this.allItems.slice();
        this.xItemsPerPage = this.itemsPerPage;
    }

    @Watch('items.length')
    onItemsChange(next, prev){
        this.allItems = this.items.slice();
        this.xData = this.allItems.slice();
        this.applyFilters();
        this.calcPagination();
    }

    mounted(){
        var rootElement: any = this.$refs.rootElement;
        var classes = [];
        rootElement.classList.forEach(x => {
            classes.push(x);
        })
        rootElement.classList.value = "";
        this.tableRoot = this.$refs.tableRoot;
        classes.forEach(x => this.tableRoot.classList.add(x));
        
        let headTds = this.tableRoot.querySelectorAll('thead td')
        headTds.forEach(x => {
            this.insertFilter(x);
            this.insertSort(x);
        })

        let opFilters = StorageServices.getOpTableFilters(this.opName);
        for(let p in opFilters){
            let filterInput = this.filterInputs.find(x => x.name == p);
            if(filterInput){
                filterInput.value = opFilters[p];
            }
        }
        this.applyFilters();
        this.calcPagination();
    }

    @Watch('xItemsPerPage')
    onItemsPerPageChange(next, prev){
        this.calcPagination();
    }

    calcPagination(){
        this.totalPages = Math.ceil((this.xData.length) / this.xItemsPerPage);
        if(this.selectedPage > this.totalPages && this.selectedPage > 1){
            this.selectedPage = this.totalPages;
        }
        this.lowerPages = this.selectedPage - 2;
        this.upperPages = this.selectedPage + 2;

        if(this.selectedPage == 1)
            this.upperPages += 2;
        else if(this.selectedPage == 2)
            this.upperPages += 1;

        if(this.selectedPage == this.totalPages)
            this.lowerPages -= 2;
        else if(this.selectedPage == this.totalPages - 1)
            this.lowerPages -= 1;

        if(this.upperPages > this.totalPages)
            this.upperPages = this.totalPages;
        if(this.lowerPages <= 0)
            this.lowerPages = 1;
            
        this.pages = [];
        for(let i = this.lowerPages; i <= this.upperPages; i++){
            this.pages.push(i);
        }
    }
    get paginated(){
        let start = this.xItemsPerPage * (this.selectedPage - 1);
        return this.xData.slice(start, start + this.xItemsPerPage) || [];
    }

    insertSort(element){
        if(!element.hasAttribute('sort'))
            return;

        let prop = element.getAttribute('sort');
        let sortDefinition = new SortDefinition();
        sortDefinition.type = "";
        sortDefinition.el = element;
        element.onclick = () => {
            if(!sortDefinition.type){
                sortDefinition.type = 'asc';
                element.classList.add('asc');
            } else if(sortDefinition.type == 'asc') {
                sortDefinition.type = 'desc';
                element.classList.remove('asc');
                element.classList.add('desc');
            } else {
                sortDefinition.type = '';
                element.classList.remove('asc');
                element.classList.remove('desc');
            }
            this.applySorts(sortDefinition);
        }
        sortDefinition.fn = (a, b) => {
            let aValue = this.getValueByProp(a, prop);
            let bValue = this.getValueByProp(b, prop);

            if(!aValue) aValue = -1;
            if(!bValue) bValue = -1;
            let aVal = aValue.toString(); //.toString().toLowerCase().trim();
            let bVal = bValue.toString(); //.toString().toLowerCase().trim();
            let result = (<string>aVal).localeCompare(bVal, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
            return sortDefinition.type == 'asc' ? -result : result;

            // if (aVal < bVal)
            //     return sortDefinition.type == 'asc' ? -1 : 1;
            // if (aVal > bVal)
            //     return sortDefinition.type == 'asc' ? 1 : -1;
            // return 0;
        }
        this.sorts.push(sortDefinition);
    }

    filterInputs: HTMLInputElement[] = [];
    insertFilter(element){
        if(!element.hasAttribute('filter'))
            return;

        var inputContainer = element.querySelector('.opTdFilterContainer');
        if(!inputContainer)
            inputContainer = document.createElement('div');
            
        var input = inputContainer.querySelector('input');
        if(!input)
            input = document.createElement('input');
        input.type = "text";
        let filterType = element.getAttribute('filterType');
        if(filterType){
            input.type = filterType;
            if(input.type == "checkbox"){
                input.dataset.checked = "0";
            }
        }
        let prop = element.getAttribute('filter');
        let filterFn = (item) => {
            let val = input.value.toString().toLowerCase().trim();
            if(input.type == "checkbox"){
                val = input.dataset.checked;
                if(val == "0") return true;
                let propVal = this.getValueByProp(item, prop);
                if(val == "1") return propVal == false;
                if(val == "2") return propVal == true;
            } else {
                if(!val) return true;
                let propVal = this.getValueByProp(item, prop);
                if(!propVal && propVal !== false) return false;
                return propVal.toString().toLowerCase().trim().indexOf(val) > -1
            }
        }
        if(this.filtersFn[prop]){
            filterFn = (item) => {
                let val = input.value.toString().toLowerCase().trim();
                if(input.type == "checkbox")
                    val = !!input.checked as any;
                if(!val) return true;
                return this.filtersFn[prop](item, val);
            }
        }

        this.filters.push(filterFn);
        if(input.type == "checkbox"){
            input.oninput = () => {
                this.checkboxCycle(input);
                this.applyFilters();
            }
        } else {
            input.oninput = this.applyFilters;
        }
        input.onclick = (ev) => ev.stopPropagation();
        inputContainer.classList.add('opTdFilterContainer')
        input.classList.add('opTdFilter')
        input.name = prop;
        this.filterInputs.push(input);
        element.append(inputContainer);
        inputContainer.append(input);
    }

    checkboxCycle(el: HTMLInputElement){
        switch(el.dataset.checked) {
            // unchecked, going indeterminate
            case "0":
                el.dataset.checked = "1";
                el.indeterminate = true;
                return false;
            // indeterminate, going checked
            case "1":
                el.dataset.checked = "2";
                el.indeterminate = false;
                el.checked = true;
                return true;
            // checked, going unchecked
            case "2":  
                el.dataset.checked = "0";
                el.indeterminate = false;
                el.checked = false;
                return null;
        }
    }

    @Watch('clearFilters')
    onClearFilters(next, prev){
        this.filterInputs.forEach(x => {
            if(x.type == 'checkbox'){
                x.dataset.checked = "0";
                x.indeterminate = false;
                x.checked = false;
            } else {
                x.value = ""
            }
        });
        this.applyFilters();
    }

    getValueByProp(input: any, propString: string){
        let props = propString.split('.');
        let ris = input;
        props.forEach(x => {
            if(ris != null)
                ris = ris[x];
        })
        return ris;
    }

    applyFilters(){
        this.xData = this.allItems.slice();
        this.filters.forEach(x => {
            this.xData = this.xData.filter(x);
        })
        this.calcPagination();
    }

    applySorts(def: SortDefinition){
        if(this.sorts.filter(x => !x.type).length == this.sorts.length){
            this.applyFilters();
        } else {
            this.sorts.forEach(x => {
                if(x.el != def.el){
                    x.el.classList.remove('asc');
                    x.el.classList.remove('desc');
                    x.type = '';
                    return;
                }
                this.xData.sort(x.fn);
            })
        }
    }

}

class SortDefinition {
    type: string;
    el: HTMLElement;
    fn: (a: any, b: any) => number;
}

</script>
<style>
</style>