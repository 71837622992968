import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/op-table.css';
import '@/css/style.css';

import FILTERS from '@/filters';
import OpModal from '@/plugins/modalPlugin';
import { CommonServices } from './services/CommonServices';
import marketplaceActivation from '@/components/marketplaceActivation/marketplaceActivation.vue';
import { LocalizationServices } from './services/LocalizationServices';

const app = createApp(App)
.use(store)
.use(router)
.use(OpModal);

app.config.globalProperties.$filters = FILTERS;
app.config.globalProperties.$localizationServices = LocalizationServices;
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

app.mount('#app');

CommonServices.showSpinnerCb = () => {
    store.state.globalSpinner++;
}
CommonServices.hideSpinnerCb = () => {
    store.state.globalSpinner--;
    if(store.state.globalSpinner < 0){
        store.state.globalSpinner = 0;
    }
}
