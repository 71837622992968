<template>
    <div>
        <div class="text-bold">
            <localized-text localizedKey="Elementi duplicati nella prima importazione" text="Elementi duplicati nella prima importazione"></localized-text>
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <td>
                        <localized-text localizedKey="Origine" text="Origine"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="OnOffice" text="OnOffice"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Google" text="Google"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Azione" text="Azione"></localized-text>
                    </td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in items" :key="i">
                    <td>
                        {{item.firstSyncOrigin}}
                    </td>
                    <td>
                        <div v-for="(ev, i) in item.onOfficeEvents">
                            {{$filters.date(ev.startDate, "DD/MM/yyyy HH:mm")}} - {{$filters.date(ev.endDate, "DD/MM/yyyy HH:mm")}}<br/>
                            {{ev.title}}
                        </div>
                    </td>
                    <td>
                        <div v-for="(ev, i) in item.googleEvents">
                            <template v-if="ev.start.date">
                                {{$filters.date(ev.start.date, "DD/MM/yyyy HH:mm")}} - {{$filters.date(ev.end.date, "DD/MM/yyyy HH:mm")}}
                            </template>
                            <template v-else>
                                {{$filters.date(ev.start.dateTime, "DD/MM/yyyy HH:mm")}} - {{$filters.date(ev.end.dateTime, "DD/MM/yyyy HH:mm")}}
                            </template>
                            <br/>
                            {{ev.title}}
                        </div>
                    </td>
                    <td>
                        <div>
                            <button class="btn btn-danger" @click="doAction(item, 'KeepOnOffice')">
                                <localized-text localizedKey="Tieni onOffice, elimina google" text="Tieni onOffice, elimina google"></localized-text>
                            </button>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-danger" @click="doAction(item, 'KeepGoogle')">
                                <localized-text localizedKey="Tieni google, elimina onOffice" text="Tieni google, elimina onOffice"></localized-text>
                            </button>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-danger" @click="doAction(item, 'KeepNone')">
                                <localized-text localizedKey="Elimina entrambi" text="Elimina entrambi"></localized-text>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { SyncErrorClient, SyncOperationsClient } from '@/services/Services'
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class firstSyncPendingItems extends Vue {

    @Prop() reload: number;

    // items: OM.FirstSyncPendingItem[] = [];

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }

    created(){
        this.init();
    }

    init(){
        // this.items = [];
        // SyncOperationsClient.getFirstSyncPendingItems(store.state.loginResponse.userId, store.state.loginResponse.webUserId)
        // .then(x => {
        //     this.items = x;
        // })
    }

    // doAction(item: OM.FirstSyncPendingItem, action: string){
    //     this.items = [];
    //     SyncOperationsClient.resolveFirstSyncPendingItem(store.state.loginResponse.userId, store.state.loginResponse.webUserId, item.identifier, action)
    //     .then(x => {
    //         this.init();
    //     })
    // }

}
</script>