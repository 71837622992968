<template>
    <div>
        <div class="accordion" :class="openThis ? 'open' : ''">
            <div class="accordionTitle" @click="openThis = !openThis">
                <slot name="title"></slot>
                <div class="accordionButton" key="up" v-if="openThis">
                    <button type="button">
                        <img src="@/img/chevron_up.svg" />
                    </button>
                </div>
                <div class="accordionButton" key="down" v-if="!openThis">
                    <button type="button">
                        <img src="@/img/chevron_down.svg" />
                    </button>
                </div>
            </div>
            <div class="accordionBody" :class="{'indent': indent}" v-if="openThis">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class accordion extends Vue {
    @Prop() open: boolean;
    @Prop({
        default: false
    }) indent: boolean;
    openThis: boolean = false;
    created(){
        if(this.open){
            this.openThis = true;
        }
    }
}

</script>

<style scoped>

    .accordion .accordionTitle {
        background: whitesmoke;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
        cursor: pointer;
    }

    .accordion .accordionButton button {
        background: none;
        border: none;
        width: 33px;
        height: 33px;
    }

    .accordion .accordionBody {
        padding-top: 10px;
    }

    .accordion .accordionBody.indent {
        padding-left: 20px;
    }

</style>
