import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "appComponent"
}
const _hoisted_2 = {
  key: 0,
  class: "testlabel"
}
const _hoisted_3 = {
  key: 1,
  class: "globalSpinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_marketplace_activation = _resolveComponent("marketplace-activation")!
  const _component_welcome_page = _resolveComponent("welcome-page")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_ctx.showApp)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$store.state.isTestMode())
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " modalità test "))
          : _createCommentVNode("", true),
        (_ctx.$store.state.globalSpinner > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_mini_spinner)
            ]))
          : _createCommentVNode("", true),
        (_ctx.underMaintenance)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 2,
              localizedKey: "Il servizio è attualmente in manutenzione.",
              text: "Il servizio è attualmente in manutenzione."
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.isActivated)
                ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                : (_ctx.isActivation)
                  ? (_openBlock(), _createBlock(_component_marketplace_activation, { key: 1 }))
                  : (_openBlock(), _createBlock(_component_welcome_page, { key: 2 })),
              _createVNode(_component_modals_container)
            ], 64))
      ]))
    : _createCommentVNode("", true)
}